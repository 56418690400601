import React from 'react'

export default function About() {
  return (
    <div>
      <h1>About</h1>
      <p>We're a team of international entrepreneurs and engineers building the technologies of tomorrow.</p>
    </div>
  )
}
